.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 35px 120px;
  margin-top: 50px;
  .footer-wrapper {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
  }
  .content-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    .main-left {
      display: flex;
      flex-direction: column;
      padding-right: 16px;
      flex: 0 0 33.333%;
      .heading-logo {
        width: 160px;
        height: 60px;
        object-fit: cover;
      }
      .left-content {
        text-align: start;
        margin: 16px 0;
        font-size: 14px;
        color: var(--gray7-color);
        font-weight: 300;
      }
      .info-footer {
        display: flex;
        .email {
          margin-right: 30%;
        }
      }
    }
    .main {
      display: flex;
      flex-direction: column;
      margin: 0 40px;
      flex: 1;
    }
    .heading-main {
      text-align: start;
      padding-top: 20px;
      color: var(--error-color);
      margin-bottom: 11px;
    }
    .main-content {
      text-align: start;
      margin: 16px 0;
      flex: 1;
    }
    .title-info,.title-jobs {
      font-weight: 300;
      font-size: 14px;
      color: var(--gray7-color);
      white-space: nowrap;
    }
    .main-right {
      display: flex;
      flex-direction: column;
      flex: 0 0 20%;
      margin: 0 40px;
    }
  }
  .title-jobs{
    cursor: pointer;
  }
  .title-footer {
    text-align: center;
  }
}
.copyright {
  font-weight: 400;
  font-size: 13px;
  color: var(--gray7-color);
}

@media only screen and (max-width: 1024px) {
  .footer {
    padding: 35px 50px;
    .content-footer {
      .main-left {
        flex: 0 0 30%;
      }
      .main {
        margin: 0 10px;
        flex: 0 0 30%;
      }
      .main-right {
        margin-left: 10px;
        flex: 0 0 30%;
        a {
          .icon {
            display: none;
          }
          .icon-active {
            display: block;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .footer {
    padding: 35px 10px;
    .content-footer {
      flex-direction: column;
      .main-left {
        .info-footer {
          display: flex;
          flex-direction: column;
        }
      }
      .main {
        margin: 0;
      }
      .main-right {
        margin: 0;
      }
    }
  }
}
