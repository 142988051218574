@import "scss/fonts";
@import "scss/variable";
@import "scss/reset";

#root_page {
  position: relative;
  overflow-x: auto;
  min-height: 100vh;
}

//flex-box
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

//dot status
.dot-status {
  width: 12px;
  height: 12px;
  border: 1px solid var(--white-color);
  border-radius: 20px;
}

.dot-online {
  background: var(--success-color);
  border-radius: 30px;
}

.dot-busy {
  background: var(--error-color);
  border-radius: 30px;
}

.dot-offline {
  background: var(--gray5-color);
  border-radius: 30px;
}

//text-voice
.online {
  color: var(--success-color);
  font-weight: 700;
  font-size: 14px;
  margin-left: 4px;
}

.offline {
  color: var(--gray5-color);
  font-weight: 700;
  font-size: 14px;
  margin-left: 4px;
}

.busy {
  color: var(--error-color);
  font-weight: 700;
  font-size: 14px;
  margin-left: 4px;
}

//icon
.icon-pause {
  height: 22px;
  width: 22px;
}

.icon-play {
  height: 22px;
  width: 22px;
}

.icon-sound {
  width: 28px;
}

.icon-send {
  width: 24px;
  height: 24px;
}

.icon-checked {
  width: 18px;
  height: 18px;
}

.icon-heart {
  width: 16px;
  height: 16px;
}

//button
.primary-btn {
  background: var(--primary-color);
  color: var(--white-color);
  border: 1px solid var(--primary-color);
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    background: var(--pink7-color);
  }
}

.primary-btn-disable {
  background: var(--primary-color);
  color: var(--white-color);
  border: 1px solid var(--primary-color);
  opacity: 0.8;
  border-radius: 12px;
}

.btn-collapse {
  background: none;
  border: none;
}

.gray-btn {
  background: var(--gray1-color);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    background: var(--gray3-color);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: var(--font-poppins), sans-serif;
  height: 100%;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: var(--white-color);
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray5-color);
    border-radius: 5px;
    border: 2px solid var(--gray5-color);
  }
}
.main-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--white-color);
  z-index: 11;
}

.current-duo-home {
  border-radius: 12px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  cursor: pointer;
  height: 50px;
  border: 1px solid var(--gray4-color);
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  img {
    display: block;
    width: 35px;
    height: 35px;
    object-fit: contain;
    border-radius: 50%;
    margin-right: 10px;
  }
  span {
    font-size: 16px;
    display: block;
    color: var(--primary-color);
    font-weight: 600;
    letter-spacing: 1.2px;
  }
  .duo-status {
    p {
      font-size: 12px;
      white-space: nowrap;
    }
  }
}
.end-list-chat {
  width: 100%;
  height: 1px;
  margin-top: 10px;
}

.status-value {
  &.wating {
    color: var(--warning-color) !important;
  }
  &.duo {
    color: var(--purple-color) !important;
  }
  &.success {
    color: var(--success-color) !important;
  }
  &.error {
    color: var(--error-color) !important;
  }
}

.btn {
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.5;
  }
}

#root_page {
  .Toastify__toast-theme--light {
    border-radius: 12px;
    box-shadow: 0 1px 10px 0 rgb(239 78 174 / 44%),
      0 2px 15px 0 rgb(239 78 174 / 14%);
    border: 1px solid var(--pink2-color);
  }
  .Toastify__toast-body {
    padding: 0;
  }
}

button {
  font-family: var(--font-poppins), sans-serif;
}

input {
  font-family: var(--font-poppins), sans-serif;
  font-weight: 400;
  &::placeholder{
    color: var(--gray5-color);
  }
  &:not([type="checkbox"]) {
    outline: none;
    -webkit-appearance: none;
  }
  &[type="number"]::-webkit-outer-spin-button,
  [type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

textarea,
select {
  font-family: var(--font-poppins), sans-serif;
  font-weight: 400;
  &::placeholder{
    color: var(--gray5-color);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-18 {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  border-top: 2px solid #fff;
  border-right: 2px solid transparent;
  animation: rotation 1s linear infinite;
  &:after {
    content: "";
    position: absolute;
    left: -1px;
    top: -2px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border-bottom: 2px solid #fff;
    border-left: 2px solid transparent;
  }
}

.bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.block-user-popup {
  border-radius: 20px;
  padding: 8px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .block-user-content {
    font-size: 14px;
    font-weight: 500;
    color: var(--gray6-color);
    text-align: center;
  }
  .block-user-img {
    margin: 16px 0;
    width: 144px;
    height: 144px;
  }
  .btn-close {
    width: 100%;
    padding: 12px;
  }
}
