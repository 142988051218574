.header {
  width: 100%;
  padding: 120px 66px;
  display: flex;
  justify-content: center;
  .header-wrapper {
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-left {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: 16px;
    .title-header {
      font-size: 40px;
      font-weight: 600;
      text-align: start;
      margin-bottom: 50px;
      transform: translateY(100%);
      transition: 0.3s cubic-bezier(0.26, 1, 0.48, 1);
      opacity: 0;
      b {
        color: var(--primary-color);
      }
    }
    .content-header {
      display: flex;
      text-align: start;
      margin-bottom: 65px;
      font-size: 16px;
      line-height: 32px;
      transform: translateY(100%);
      transition: 0.3s cubic-bezier(0.26, 1, 0.48, 1);
      opacity: 0;
    }
    .contact-btn {
      padding: 12px 36px;
      font-size: 16px;
      font-weight: 600;
      color: var(--white-color);
      background: var(--primary-color);
      box-shadow: 0px 13px 28px rgba(0, 0, 0, 0.2);
      border: none;
      border-radius: 8px;
      cursor: pointer;
      opacity: 0;
      transform: scaleZ(0);
      transition: 1s cubic-bezier(0.26, 1, 0.48, 1);
      &:hover {
        background: var(--primary-hover);
      }
    }
    &.animate {
      .title-header {
        font-size: 40px;
        font-weight: 600;
        transform: translateY(0);
        transition: 1s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
      }
      .content-header {
        font-size: 16px;
        line-height: 32px;
        transform: translateY(0);
        transition: 1s cubic-bezier(0.26, 1, 0.48, 1);
        transition-delay: 0.3s;
        opacity: 1;
      }
      .contact-btn {
        font-size: 16px;
        font-weight: 600;
        transform: scaleZ(1);
        transition: 1s cubic-bezier(0.26, 1, 0.48, 1);
        transition-delay: 1s;
        opacity: 1;
      }
    }
  }
  .header-right {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateX(100%);
    transition: 0.5s cubic-bezier(0.26, 1, 0.48, 1);
    opacity: 0;
    object-fit: cover;
    img {
      animation: MoveUpDown 3s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
      animation-delay: 2s;
    }
    &.animate {
      transform: translateX(0);
      transition: 1s cubic-bezier(0.26, 1, 0.48, 1);
      transition-delay: 1s;
      opacity: 1;
    }
  }
}

@keyframes MoveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 1200px) {
  .header {
    padding: 120px 20px 60px;
    margin-top: 50px;
    .header-left {
      width: 60%;
      margin-right: 16px;
      .title-header {
        font-size: 30px;
      }
      .content-header {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .header {
    .header-wrapper {
      display: flex;
      flex-direction: column-reverse;
    }
    .header-left {
      margin-top: 50px;
      width: 100%;
    }
    .header-right {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 16px;
      .img-cpu {
        width: 80%;
      }
    }
  }
}
