.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .bg-home {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    max-height: 100%;
  }
}
