.sec3 {
  width: 100%;
  margin: 100px auto 0;
  display: flex;
  .main-left {
    display: flex;
    flex-direction: column;
    text-align: start;
    margin-right: 48px;
    width: 50%;
    transform: translateY(30%);
    transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
    opacity: 0;
    .content-left {
      margin: 8px 0;
      color: var(--gray6-color);
      font-size: 14px;
      font-weight: 400;
    }
    .list-checked {
      display: flex;
      flex-direction: column;
      .checked-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .icon-checked {
          width: 36px;
          height: 36px;
          margin-right: 16px;
        }
      }
    }
    &.animate {
      transform: translateY(0);
      transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
      opacity: 1;
    }
  }
  .main-right {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    .sec3-item {
      display: flex;
      margin-bottom: 16px;
      margin-right: 16px;
      flex: 0 0 calc(33.333% - 16px);
      max-width: 33.333%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: var(--white-color);
      padding: 30px 0;
      box-shadow: -10px -10px 20px #ffffff, 10px 10px 20px #d2d2d2;
      border-radius: 20px;
      transform: translateY(30%);
      transition: 0.3s cubic-bezier(0.26, 1, 0.48, 1);
      opacity: 0;
      .img-item {
        width: 60px;
        height: 60px;
        margin-bottom: 8px;
      }
    }
    &.animate {
      :first-child {
        transform: translateY(0);
        transition: 0.3s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
      }
      :nth-child(2) {
        transform: translateY(0);
        transition: 0.3s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
        transition-delay: 0.1s;
      }
      :nth-child(3) {
        transform: translateY(0);
        transition: 0.3s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
        transition-delay: 0.2s;
      }
      :nth-child(4) {
        transform: translateY(0);
        transition: 0.3s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
        transition-delay: 0.3s;
      }
      :nth-child(5) {
        transform: translateY(0);
        transition: 0.3s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
        transition-delay: 0.4s;
      }
      :last-child {
        transform: translateY(0);
        transition: 0.3s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
        transition-delay: 0.5s;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .sec3 {
    .main-left {
      width: 40%;
    }
  }
}

@media only screen and (max-width: 900px) {
  .sec3 {
    flex-direction: column;
    .main-left {
      width: 100%;
      margin-bottom: 50px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .sec3 {
    padding: 0;
    .main-right {
      .sec3-item {
        flex: 0 0 calc(50% - 16px);
        max-width: 50%;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .sec3 {
    .main-right {
      .sec3-item {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
