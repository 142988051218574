.sec2 {
  .sec2-item1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .left-item {
      display: flex;
      width: 532px;
      align-items: center;
      margin-top: 50px;
      transform: translateY(30%);
      transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
      opacity: 0;
      margin-right: 32px;
      .icon-service {
        display: flex;
        padding-top: 20px;
        margin-right: 16px;
        img {
          width: 80px;
          height: 80px;
          filter: drop-shadow(10px 10px 14px rgba(0, 0, 0, 0.15));
        }
      }
      .content-sec2 {
        text-align: start;
        h1 {
          color: var(--primary-color);
        }
        p {
          width: 100%;
        }
      }
    }
    .right-item {
      width: 457px;
      display: flex;
      align-items: center;
      margin-top: 50px;
      opacity: 0;
      transform: translateX(30%);
      transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
      img {
        animation: MoveUpDown 3s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
        animation-delay: 3s;
      }
    }
    &.animate {
      .left-item {
        transform: translateY(0);
        transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
      }
      .right-item {
        transform: translateX(0);
        transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
      }
    }
  }
  .sec2-item2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .left-item {
      width: 457px;
      display: flex;
      align-items: center;
      opacity: 0;
      transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
      transform: translateX(-30%);
      margin-right: 32px;
      img {
        animation: MoveUpDown 3s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
        animation-delay: 2s;
      }
    }
    .right-item {
      display: flex;
      width: 532px;
      align-items: center;
      transform: translateY(30%);
      transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
      opacity: 0;
      .icon-service {
        display: flex;
        padding-top: 20px;
        margin-right: 16px;
        img {
          width: 80px;
          height: 80px;
          filter: drop-shadow(10px 10px 14px rgba(0, 0, 0, 0.15));
        }
      }
      .content-sec2 {
        text-align: start;
        h1 {
          color: var(--primary-color);
        }
        p {
          width: 100%;
        }
      }
    }
    &.animate {
      .left-item {
        transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
        transform: translateX(0);
      }
      .right-item {
        transform: translateY(0);
        transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
      }
    }
  }
  .sec2-item3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .left-item {
      display: flex;
      width: 532px;
      align-items: center;
      transform: translateY(30%);
      transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
      opacity: 0;
      margin-right: 32px;
      .icon-service {
        display: flex;
        padding-top: 20px;
        margin-right: 16px;

        img {
          width: 80px;
          height: 80px;
          filter: drop-shadow(10px 10px 14px rgba(0, 0, 0, 0.15));
        }
      }
      .content-sec2 {
        text-align: start;
        h1 {
          color: var(--primary-color);
        }
        p {
          width: 100%;
        }
      }
    }
    .right-item {
      width: 457px;
      display: flex;
      align-items: center;
      margin-top: 50px;
      opacity: 0;
      transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
      transform: translateX(30%);
      img {
        animation: MoveUpDown 3s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
        animation-delay: 2s;
      }
    }
    &.animate {
      .left-item {
        transform: translateY(0);
        transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
      }
      .right-item {
        transform: translateX(0);
        transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
      }
    }
  }
  .sec2-item4 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-item {
      width: 457px;
      display: flex;
      align-items: center;
      transform: translateX(-30%);
      transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
      opacity: 0;
      margin-right: 32px;
      img {
        animation: MoveUpDown 3s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
        animation-delay: 2s;
      }
    }
    .right-item {
      display: flex;
      width: 532px;
      transform: translateY(30%);
      transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
      opacity: 0;
      .icon-service {
        display: flex;
        padding-top: 20px;
        margin-right: 16px;
        img {
          width: 80px;
          height: 80px;
          filter: drop-shadow(10px 10px 14px rgba(0, 0, 0, 0.15));
        }
      }
      .content-sec2 {
        text-align: start;
        h1 {
          color: var(--primary-color);
        }
        p {
          width: 100%;
        }
      }
    }
    &.animate {
      .left-item {
        transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
        transform: translateX(0);
      }
      .right-item {
        transform: translateY(0);
        transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .sec2 {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 900px) {
  .sec2 {
    .sec2-item1 {
      display: flex;
      flex-direction: column-reverse;
      .right-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-service {
          width: 70%;
          height: 100%;
        }
      }
      .left-item {
        width: 100%;
        margin-top: 50px;
        margin-right: 0;
      }
    }
    .sec2-item2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 0;
      .right-item {
        width: 100%;
        margin-top: 50px;
      }
      .left-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0;
        .icon-service {
          width: 70%;
          height: 100%;
        }
      }
    }
    .sec2-item3 {
      display: flex;
      flex-direction: column-reverse;
      .right-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-service {
          width: 70%;
          height: 100%;
          mix-blend-mode: multiply;
        }
      }
      .left-item {
        width: 100%;
        margin-top: 50px;
        margin-right: 0;
      }
    }
    .sec2-item4 {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 0;
      .right-item {
        width: 100%;
        margin-top: 50px;
      }
      .left-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        margin-right: 0;
        .icon-service {
          width: 70%;
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .sec2 {
    padding: 0;
  }
}

@keyframes MoveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
