.popup-submit-success{
    background: var(--white-color);
    padding: 16px 16px 24px;
    border-radius: 20px;
    .top-popup{
        display: flex;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--gray5-color);
        .title-popup{
            width: 100%;
            text-align: center;
            padding-left: 24px;
        }
        .btn-close{
            width: 24px;
            height: 24px;
            background: none;
            border: none;
            cursor: pointer;
        }
    }
    .content-popup{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 16px;
        .img-success{
            max-width: 130px;
            margin-bottom: 16px;
        }
        .content-popup-text{
            max-width: 80%;
            font-size: 14px;
            font-weight: 400;
            color: var(--gray5-color);
            line-height: 21px;
            text-align: center;
        }
    }
}