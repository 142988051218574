.sec1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 50px 0;
  .sec1-item {
    flex: 0 0 calc(24% - 16px);
    padding: 45px 20px;
    background: var(--white-color);
    border-radius: 20px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    text-align: start;
    opacity: 0;
    transform: translateY(50%);
    transition: 0.3s cubic-bezier(0.26, 1, 0.48, 1);
    &:last-child{
      margin: 0;
    }
    &.show1 {
      &:first-child {
        transform: translateY(0);
        transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
      }
      &:nth-child(2) {
        transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
        transform: translateY(0);
        opacity: 1;
        transition-delay: 0.2s;
      }
      &:nth-child(3) {
        transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
        transform: translateY(0);
        opacity: 1;
        transition-delay: 0.3s;
      }
      &:last-child {
        transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
        transform: translateY(0);
        opacity: 1;
        transition-delay: 0.4s;
      }
    }
    .img-sec1 {
      width: 60px;
      height: 60px;
      object-fit: cover;
      margin-bottom: 20px;
    }
    .title-sec1 {
      margin-bottom: 8px;
      font-size: 16px;
    }
    .content-sec1 {
      color: var(--gray6-color);
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .sec1 {
    padding: 0;
    .sec1-item {
      flex: 0 0 calc(50% - 16px);
      margin-bottom: 16px;
      &:nth-child(2), &:nth-child(3){
        margin-right: 0;
      }
      &:last-child{
        margin-bottom: 16px;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .sec1 {
    .sec1-item {
      flex: 0 0 100%;
    }
  }
}
