.about-us {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 200px 150px;
  .about-wrapper {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .our-technology {
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    h1 {
      transform: translateY(30%);
      transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
      opacity: 0;
    }
    .list-product {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 80px;
      .product-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 25%;
        max-width: 25%;
        transform: translateY(50%);
        transition: 0.3s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 0;
        .img-product {
          width: 185px;
          height: 185px;
        }
        .title-product {
          font-weight: 600;
          color: var(--gray5-color);
        }
      }
      :first-child {
        transition-delay: 0.4s;
      }
      :nth-child(2) {
        transition-delay: 0.3s;
      }
      :nth-child(3) {
        transition-delay: 0.2s;
      }
    }
    &.animate {
      h1 {
        transform: translateY(0);
        transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
      }
      .list-product {
        :first-child {
          transform: translateY(0);
          transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
          opacity: 1;
        }
        :nth-child(2) {
          transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
          transform: translateY(0);
          opacity: 1;
          transition-delay: 0.2s;
        }
        :nth-child(3) {
          transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
          transform: translateY(0);
          opacity: 1;
          transition-delay: 0.3s;
        }
        :last-child {
          transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
          transform: translateY(0);
          opacity: 1;
          transition-delay: 0.4s;
        }
      }
    }
  }
  .our-team {
    width: 100%;
    display: flex;
    flex-direction: column;
    // margin-top: 120px;
    text-align: center;
    h1 {
      transform: translateY(30%);
      transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
      opacity: 0;
    }
    .list-member {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 40px;
      .member-item {
        flex: 0 0 33.333%;
        max-width: 33.333%;
        transform: translateY(50%);
        transition: 0.3s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 0;
        .name-member {
          font-size: 18px;
          font-weight: 600;
        }
        .img-member {
          width: 200px;
          height: 200px;
          border-radius: 50%;
        }
        .office-member {
          text-align: center;
          color: var(--gray6-color);
          font-weight: 500;
        }
      }
      :first-child {
        transition-delay: 0.3s;
      }
      :nth-child(2) {
        transition-delay: 0.2s;
      }
    }
    &.animate {
      h1 {
        transform: translateY(0);
        transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
        opacity: 1;
      }
      .list-member {
        :first-child {
          transform: translateY(0);
          transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
          opacity: 1;
        }
        :nth-child(2) {
          transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
          transform: translateY(0);
          opacity: 1;
          transition-delay: 0.2s;
        }
        :last-child {
          transition: 0.6s cubic-bezier(0.26, 1, 0.48, 1);
          transform: translateY(0);
          opacity: 1;
          transition-delay: 0.3s;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .about-us {
    padding: 120px 50px 0;
    .our-team {
      .list-member {
        .member-item {
          flex: 0 0 33.333%;
          max-width: 33.333%;
          padding: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .about-us {
    padding: 120px 10px 0;
    .our-technology {
      .list-product {
        .product-item {
          flex: 0 0 50%;
          max-width: 50%;
          margin-bottom: 10px;
        }
      }
    }
  }
  .our-team {
    .list-member {
      .member-item {
        flex: 0 0 33.333%;
        max-width: 33.333%;
        padding: 10px;
        .img-member {
          width: 200px;
          height: 200px;
          border-radius: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .about-us {
    padding: 120px 0 0;
    .our-team {
      .list-member {
        margin-top: 50px;
        .member-item {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0;
          margin-top: 50px;
          .img-member {
            width: 150px;
            height: 150px;
            border-radius: 50%;
          }
          // &:nth-child(1),
          // &:nth-child(3) {
          //   padding-top: 50px;
          // }
          // &:nth-child(2) {
          //   .img-member {
          //     width: 200px;
          //     height: 200px;
          //   }
          // }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .about-us .our-technology .list-product {
    padding: 0 10px;
    .product-item {
      flex: 0 0 100%;
      max-width: 100%;
      .title-product {
        width: 100%;
        padding: 50px 10px;
        background: var(--gray2-color);
        border-radius: 12px;
      }
    }
  }
}
