.service {
  width: 100%;
  padding: 62px 150px;
  background: #f3f3f3;
  display: flex;
  justify-content: center;
  .service-wrapper {
    max-width: 1440px;
  }
}

@media only screen and (max-width: 1024px) {
  .service {
    padding: 50px 40px;
  }
}

@media only screen and (max-width: 675px) {
  .service {
    padding: 50px 20px;
  }
}
