.detail-jobs {
  width: 100%;
  display: flex;
  justify-content: center;
}

.detail-wrapper {
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 70px 100px;
  .top-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .back-page {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      .text-back {
        text-decoration: underline;
      }
    }
    .icon-back {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    .text-back {
      font-size: 16px;
      font-weight: 600;
      text-decoration: underline;
      b {
        color: var(--primary-color);
        text-decoration: underline var(--primary-color);
      }
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background: var(--gray4-color);
    margin: 20px 0;
  }
  .content-detail-wrapper {
    .top-content-detail {
      .location-time {
        display: flex;
        align-items: center;
        margin-top: 16px;
      }
      .location-work {
        display: flex;
        align-items: center;
        .icon-location {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        .text-location {
          color: var(--gray5-color);
          font-size: 14px;
        }
      }
      .time-work {
        padding: 4px 16px;
        border: 1px solid var(--gray5-color);
        color: var(--gray5-color);
        border-radius: 16px;
        font-size: 14px;
        margin-left: 16px;
      }
    }
    .requirement {
      font-size: 16px;
      font-weight: 600;
      margin-top: 20px;
    }
    .main-content-item {
      display: flex;
      flex-direction: column;
    }
    .detail-item {
      margin-top: 40px;
      .detail-item-content {
        margin-top: 16px;
        padding-left: 40px;
        font-size: 16px;
        color: var(--gray8-color);
      }
    }
  }
}

.apply-job {
  background: no-repeat center url("../../assets/images/icons/bg-send.png");
  text-align: center;
  border-radius: 30px;
  padding: 45px 50px;
  margin-top: 120px;
  box-shadow: 14px 16px 30px rgba(0, 0, 0, 0.1);
  z-index: 1;
  .title-form {
    max-width: 400px;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    margin: 0 auto 40px;
    b {
      color: var(--primary-color);
    }
  }
}

.form-contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .info-user {
    width: 70%;
    display: flex;
    margin-bottom: 16px;

    .input-name {
      margin-right: 16px;
    }
  }
  .input-name,
  .input-phone,
  .input-email {
    width: 100%;
    filter: drop-shadow(4px 8px 10px rgba(0, 0, 0, 0.12));
    background: var(--white-color);
    padding: 20px;
    border-radius: 10px;
    border: inherit;
    display: flex;
    align-items: center;
  }
  .error {
    font-size: 12px;
    font-weight: 400;
    color: var(--primary-color);
  }
  .input-item {
    width: 100%;
    background: none;
    border: none;
    flex: 1;
  }
  .upload-file {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--white-color);
    .title-input-upload {
      display: flex;
      flex-direction: column;
      align-items: start;
      .cate-upload {
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: start;
        flex-direction: column;
        color: var(--primary-color);
        margin-bottom: 8px;
      }
      .name-file-upload {
        display: flex;
        align-items: center;
        background: var(--link-color);
        padding: 4px 4px 4px 16px;
        font-size: 16px;
        font-weight: 400;
        color: var(--white-color);
        border-radius: 35px;
        border: 1px solid var(--link-color);
        cursor: pointer;
        text-decoration: none;
        margin-top: 8px;
        .icon-remove {
          width: 24px;
          height: 24px;
          margin-left: 16px;
        }
      }
      .note-support {
        font-size: 12px;
        color: var(--gray5-color);
        text-align: start;
      }
    }
    .upload-input {
      .btn-upload {
        padding: 8px 24px;
        border: 1px solid var(--link-color);
        background: var(--white-color);
        color: var(--link-color);
        border-radius: 40px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        &:hover {
          background: var(--gray1-color);
        }
      }
      .input-file {
        display: none;
      }
    }
  }
  .input-detail,
  .upload-file {
    filter: drop-shadow(4px 8px 10px rgba(0, 0, 0, 0.12));
    width: 70%;
    padding: 20px;
    border-radius: 10px;
    border: inherit;
    margin-bottom: 16px;
    outline: none;
  }
  .input-email {
    width: 70% !important;
    margin-bottom: 16px;
  }

  .send-btn {
    padding: 20px 50px;
    background: var(--black-color);
    color: var(--white-color);
    border: inherit;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-top: 16px;
    margin-left: 12px;
    &:hover {
      background: #393939;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .detail-wrapper {
    margin-top: 80px;
    padding: 70px 10% 20px;
  }
  .form-contact {
    .info-user,
    .input-email,
    .input-detail,
    .upload-file {
      width: 90% !important;
    }
  }
}
@media only screen and (max-width: 768px) {
  .detail-wrapper {
    padding: 70px 10px 20px;
  }
  .detail-wrapper .content-detail-wrapper .detail-item .detail-item-content {
    padding-left: 0;
  }
  .form-contact {
    padding: 0 10px;
    .info-user {
      flex-direction: column;
      .input-name {
        margin-bottom: 16px;
      }
    }
    .info-user,
    .input-email,
    .input-detail,
    .upload-file {
      width: 100% !important;
    }
  }
}
