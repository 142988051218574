.App {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

input[type="number"]::-webkit-outer-spin-button,
[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.landing-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.landing-container .landing-body {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.img-loading {
  position: fixed;
  top: 10%;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: var(--pink4-color);
  &.hide {
    transition: opacity 0.3s ease-in;
    opacity: 0;
    // z-index: -1;
    pointer-events: none;
  }
}

.img-loading .icon-loading {
  position: absolute;
  top: 30%;
  left: 30%;
  max-width: 40%;
  object-fit: cover;
  opacity: 1;
}
