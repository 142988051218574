* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: var(--font-poppins), sans-serif;
  background: var(--white-color);
  height: 100%;
}

html ::-webkit-scrollbar,
body ::-webkit-scrollbar {
  width: 4px;
}

html ::-webkit-scrollbar-track,
body ::-webkit-scrollbar-track {
  background: var(--white-color);
}

html ::-webkit-scrollbar-thumb,
body ::-webkit-scrollbar-thumb {
  background-color: var(--gray5-color);
  border-radius: 5px;
  border: 2px solid var(--gray5-color);
}


@font-face {
  src: url(assets/fonts/Poppins-Bold.ttf);
  font-family: OpenSans;
  font-weight: 300;
}

@font-face {
  src: url(assets/fonts/Poppins-Regular.ttf);
  font-family: Poppins;
  font-weight: 400;
}

@font-face {
  src: url(assets/fonts/Poppins-Medium.ttf);
  font-family: Poppins;
  font-weight: 500;
}

@font-face {
  src: url(assets/fonts/Poppins-SemiBold.ttf);
  font-family: Poppins;
  font-weight: 600;
}

@font-face {
  src: url(assets/fonts/Poppins-Bold.ttf);
  font-family: Poppins;
  font-weight: 700;
}

:root {
  --primary-color: #ef4eae;
  --secondary-color: #46aefa;
  --white-color: #fff;
  --black-color: #000;
  --link-color: #608dff;
  --yellow-color: #ff9b00;
  --gray9-color: #424242;
  --gray8-color: #616161;
  --gray7-color: #757575;
  --gray6-color: #9E9E9E;
  --gray5-color: #BDBDBD;
  --gray4-color: #E0E0E0;
  --gray3-color: #EEEEEE;
  --gray2-color: #F5F5F5;
  --gray1-color: #FAFAFA;
  --gray-light: #97adb6;
  --pink9-color: #AE0071;
  --pink8-color: #ae0071;
  --pink7-color: #DD007E;
  --pink5-color: #EF0099;
  --pink3-color: #f086c7;
  --pink2-color: #F4B7DE;
  --pink1-color: #FBE2F1;
  --success-color: #26b470;
  --error-color: #f03738;
  --warning-color: #ffc104;
  --purple-color: #796eff;
  --gradient-primary: linear-gradient(
    180deg,
    #ef4eae 34.69%,
    #ef53a3 56.04%,
    #ef7e4e 100%
  );
  --gradient1: linear-gradient(291.83deg, #ff9746 1.84%, #ffca51 97.38%);
  --gradient2: linear-gradient(
    134.97deg,
    #ef4eae 0%,
    #ef6287 40.96%,
    #ef7e4e 100.06%
  );
  --gradient3: linear-gradient(
    180deg,
    #ef4eae 34.69%,
    #ef53a3 56.04%,
    #ef7e4e 100%
  );
  --shadow-color: 0px -8px 10px rgba(0, 0, 0, 0.1);
  --shadow1-color: 0px 8px 10px rgba(0, 0, 0, 0.1);
  --shadow2-color: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  --shadowpink-color: 0px 0px 10px rgba(239, 78, 174, 0.6);
  --font-open-sans: "OpenSans";
  --font-poppins: "Poppins";
}